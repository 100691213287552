<template>
	<aside class="cart" :class="{'cart--active': active}">
		<div class="cart__top">
			<p>Your cart ({{ getNumberOfCartItems }})</p>
			<button @click="closeCart">Close</button>
		</div>
		<div class="cart__items">
			<ul v-if="getNumberOfCartItems > 0">
				<li v-for="(lineItem, key) in getLineItems" :key="`lineItem${key}`">
					<img :src="lineItem.image" />
					<p
						class="pt-4"
						v-html="
							`${lineItem.title} x ${lineItem.quantity} ${
								lineItem.pre_order ? '(Pre-Order)' : ''
							}`
						"></p>
					<p>${{ lineItem.price }}</p>
					<button class="remove" @click="removeItem(lineItem)">Remove</button>
				</li>
			</ul>
			<p v-else>There are currently no items in your cart</p>
		</div>
		<div class="cart__checkout">
			<p>
				Subtotal: ${{
					`${
						parseInt(getCheckoutSubTotal, 10) > 0
							? getCheckoutSubTotal
							: getCartTotal()
					}`
				}}
			</p>
			<button
				v-if="getNumberOfCartItems > 0"
				class="btn btn--lrg btn--blk"
				@click="checkout()">
				Checkout
			</button>
		</div>
	</aside>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
	name: 'Cart',
	props: {
		active: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			cartItems: null
		}
	},
	computed: {
		...mapGetters([
			'getLineItems',
			'getNumberOfCartItems',
			'getCheckoutSubTotal',
			'getCartTotal'
		])
	},
	methods: {
		...mapActions(['removeItemFromCart']),
		...mapMutations(['addLineItem', 'removeLineItem']),
		removeItem(lineItem) {
			this.removeItemFromCart(lineItem)
		},
		closeCart() {
			this.$mitt.emit('toggleCart', false)
		},
		checkout() {
			// go to Shopify checkout Url
			location.assign(this.$store.state.CartStore.checkoutUrl)
			// this.$router.push('/shop/checkout')
		}
	}
}
</script>

<style lang="scss" scoped>
$width: calc(33.3% + #{$site-padding-lrg});
$mobile-width: calc(80% + #{$site-padding-lrg});

.cart {
	position: fixed;
	top: 0;
	right: 0;
	width: $width;
	height: 100%;
	display: flex;
	background-color: #000;
	color: #fff;
	z-index: 100;
	@apply transition-transform duration-300 ease-in-out;
	transform: translateX(100%);

	&--active {
		transform: translateX(0);
	}

	&__top {
		display: flex;
		height: 36px;
		align-items: center;
		justify-content: space-between;
		width: calc(100% - (#{$site-padding-lrg} * 2));
		position: absolute;
		background-color: #000;
		padding: 0.5rem $site-padding-lrg;

		button {
			font-size: 0;
			height: 25px;
			width: 25px;
			position: relative;
			border: none;
			background-color: transparent;
			cursor: pointer;

			&:before {
				content: '';
				position: absolute;
				display: block;
				top: 50%;
				left: 50%;
				width: 2px;
				height: 100%;
				background-color: #fff;
				transform: translate(-50%, -50%) rotate(-45deg);
			}

			&:after {
				content: '';
				position: absolute;
				display: block;
				top: 50%;
				left: 50%;
				width: 2px;
				height: 100%;
				background-color: #fff;
				transform: translate(-50%, -50%) rotate(45deg);
			}
		}
	}

	&__items {
		margin: 36px 0 140px 0;
		padding: 0 $site-padding-lrg;
		overflow-y: scroll;
		width: 100%;

		ul {
			li {
				margin: 0 0 $site-padding-lrg 0;

				img {
					max-height: 300px;
				}

				.remove {
					@include button-resets;
					margin: $site-padding-lrg 0 0 0;
					color: #fff;
					cursor: pointer;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&__checkout {
		border-top: solid 1px #fff;
		padding: $site-padding-lrg 0;
		margin: 0 $site-padding-lrg;
		position: absolute;
		bottom: 0;
		width: calc(100% - (#{$site-padding-lrg} * 2));
		background-color: #000;

		button {
			border-color: #fff;
			margin: $site-padding-lrg 0 0 0;
		}
	}

	@include mobile {
		width: $mobile-width;
	}
}
</style>
