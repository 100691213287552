export const SpacesFilterStore = {
	namespace: false,
	state: {
		types: null,
		cities: null,
		loading: false
	},
	getters: {
		getSpaceTypes: (state) => state.types,
		getSpaceCities: (state) => state.cities,
		getSpaceLoading: (state) => state.loading
	},
	mutations: {
		setSpaceLoading(state, loading) {
			state.loading = loading
		},
		setSpaceTypes(state, types) {
			state.types = types
			state.loading = false
		},
		setSpaceCities(state) {
			if (!state.loading && !state.cities) {
				state.loading = true
				fetch(`${import.meta.env.VITE_APP_API_ADDRESS}/space/city`, {
					headers: this.$headers
				})
					.then((response) => response.json())
					.then((data) => {
						const cities = data.map((city) => {
							return {
								name: city.name,
								path: city.slug
							}
						})
						state.cities = [
							{
								name: 'All',
								path: ''
							},
							...cities
						]
					})
					.finally(() => {
						state.loading = false
					})
			}
		}
	}
}
