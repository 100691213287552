export const CartStore = {
	nameSpace: false,
	state: {
		lineItems: [], // JSON.parse(window.localStorage.getItem('SHOP_ITEMS')) || [], // eg. { variantId: '', image: '', title: '', price: '', quantity: '' }
		checkoutId: null,
		checkoutUrl: null,
		checkoutSubTotal: null,
		checkoutTax: null,
		checkoutTotal: null,
		shippingRates: [],
		shippingHandle: null,
		cartTotal: 0
	},
	getters: {
		getLineItems: (state) => state.lineItems,
		getNumberOfCartItems: (state) => state.lineItems.length || 0,
		getCheckoutId: (state) => state.checkoutId,
		getCheckoutUrl: (state) => state.checkoutUrl,
		getCheckoutSubTotal: (state) => state.checkoutSubTotal,
		getCheckoutTax: (state) => state.checkoutTax,
		getCheckoutTotal: (state) => state.checkoutTotal,
		getshippingRates: (state) => state.shippingRates,
		getshippingHandle: (state) => state.shippingHandle,
		getCartTotal: (state) => () => {
			const total = state.lineItems.reduce((total, lineItem) => {
				return total + lineItem.price * lineItem.quantity
			}, 0)

			return total
		},
		getCartItemsTotal: (state) => () => {
			const totalItems = state.lineItems.reduce((total, lineItem) => {
				return total + lineItem.quantity
			}, 0)

			return totalItems
		}
	},
	mutations: {
		addLineItemsToStorage(state) {
			window.localStorage.setItem('SHOP_ITEMS', JSON.stringify(state.lineItems))
		},
		addLineItem(state, lineItemObject) {
			// get index of line item in state line items => if item index equals -1, it is a new item
			const itemIndex = state.lineItems.findIndex((item) => {
				return item.variantId === lineItemObject.variantId
			})

			// new line item
			if (itemIndex === -1) {
				state.lineItems.push(lineItemObject)
			} else {
				// update existing
				state.lineItems[itemIndex].quantity++
			}
		},
		removeLineItem(state, lineItemObject) {
			state.lineItems = state.lineItems.filter((item) => {
				if (item.variantId !== lineItemObject.variantId) return true
			})
		},
		addCheckoutId(state, checkoutId) {
			state.checkoutId = checkoutId
		},
		addCheckoutSubTotal(state, amount) {
			state.checkoutSubTotal = amount
		},
		addCheckoutTax(state, amount) {
			state.checkoutTax = amount
		},
		addCheckoutTotal(state, amount) {
			state.checkoutTotal = amount
		},
		addCheckoutUrl(state, checkoutUrl) {
			state.checkoutUrl = checkoutUrl
		}
	},
	actions: {
		async addItemsToShopify({state}) {
			// add to shopify
			const addItems = new Promise(async (resolve) => {
				// shop url for updating & adding to cart
				const url = `${import.meta.env.VITE_APP_SHOP_ADDRESS}/cart/add`

				// add then items to cart
				const cart = await fetch(url, {
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: JSON.stringify({
						lineItems: state.lineItems,
						checkoutId: state.checkoutId
					})
				})

				const response = await cart.json()

				const checkout = response.checkoutLineItemsReplace.checkout

				resolve(checkout)
			})

			// add items and get checkout from addItems promise above
			const checkout = await addItems

			return checkout
		},
		async removeItemFromCart({commit, dispatch}, item) {
			// remove line item
			commit('removeLineItem', item)

			const update = await dispatch('addItemsToShopify')

			commit('addCheckoutId', update.id)

			commit('addCheckoutUrl', update.webUrl)

			commit('addCheckoutSubTotal', update.subtotalPriceV2.amount)

			commit('addCheckoutTax', update.totalTaxV2.amount)

			commit('addCheckoutTotal', update.totalPriceV2.amount)

			commit('addLineItemsToStorage')

			return update
		},
		async dispatchAddToCart({commit, dispatch, state}, item) {
			// create checkout when this is the first item added to cart
			if (!state.checkoutId || state.checkoutId === null) {
				const initialCheckout = await dispatch('createInitialCart', item)

				return initialCheckout
			}

			// cart exists, lets add item and update cart
			commit('addLineItem', item)

			// add to shopify
			const addItems = new Promise(async (resolve) => {
				// shop url for updating & adding to cart
				const url = `${import.meta.env.VITE_APP_SHOP_ADDRESS}/cart/add`

				// add then items to cart
				const cart = await fetch(url, {
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: JSON.stringify({
						lineItems: state.lineItems,
						checkoutId: state.checkoutId
					})
				})

				const response = await cart.json()

				const checkout = response.checkoutLineItemsReplace.checkout

				resolve(checkout)
			})

			// add items and get checkout from addItems promise above
			const checkout = await addItems

			// add checkout id & url to store
			commit('addCheckoutId', checkout.id)

			commit('addCheckoutUrl', checkout.webUrl)

			commit('addCheckoutSubTotal', checkout.subtotalPriceV2.amount)

			commit('addCheckoutTax', checkout.totalTaxV2.amount)

			commit('addCheckoutTotal', checkout.totalPriceV2.amount)

			commit('addLineItemsToStorage')

			return checkout
		},
		createInitialCart({commit}, item) {
			// creating initial cart
			const url = `${import.meta.env.VITE_APP_SHOP_ADDRESS}/cart/create`

			return new Promise(async (resolve) => {
				const cart = await fetch(url, {
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: JSON.stringify({
						lineItems: [item]
					})
				})
				const response = await cart.json()

				const checkout = response.checkoutCreate.checkout

				// add line item
				commit('addLineItem', item)

				// add checkout id & url to store
				commit('addCheckoutId', checkout.id)

				commit('addCheckoutUrl', checkout.webUrl)

				commit('addCheckoutSubTotal', checkout.subtotalPriceV2.amount)

				commit('addCheckoutTax', checkout.totalTaxV2.amount)

				commit('addCheckoutTotal', checkout.totalPriceV2.amount)

				commit('addLineItemsToStorage')

				// return / resolve checkout
				resolve(checkout)
			})
		},
		addCheckoutId(state, checkoutId) {
			state.checkoutId = checkoutId
		}
	}
}
