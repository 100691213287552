<template>
	<footer
		v-if="content"
		ref="footer"
		class="footer"
		:class="{
			'footer--copyright': includeCopyright || $route.meta.includeCopyright
		}">
		<div
			v-if="includeCopyright || $route.meta.includeCopyright"
			class="footer__copyright">
			<p
				v-if="
					$route.name.toLowerCase().includes('theory') ||
					$route.name.toLowerCase().includes('series')
				">
				All articles and publications commissioned or created by Molonglo. ©
				Molonglo {{ new Date().getFullYear() }}.
			</p>
			<p v-if="$route.name.toLowerCase().includes('practice')">
				All projects and programs commissioned or created by Molonglo. ©
				Molonglo {{ new Date().getFullYear() }}.
			</p>
		</div>
		<div class="footer-container">
			<div v-if="!$route.meta.minimalFooter" class="mb-32">
				<p v-html="content.newsletter"></p>
				<button
					v-if="$route.name !== 'subscribe'"
					@click.passive="showNewsletter = !showNewsletter"
					class="subscribe-button"
					v-text="showNewsletter ? 'Close' : 'Subscribe'" />

				<Newsletter v-show="showingNewsletter" />
			</div>
			<ul
				v-if="!$route.meta.minimalFooter && content.links"
				class="footer__links">
				<li v-for="(item, key) in content.links" :key="key">
					<a
						v-if="item.external === true"
						:href="item.link"
						target="_blank"
						rel="noopener noreferrer"
						v-html="item.name">
					</a>
					<router-link v-else :to="item.link">
						{{ item.name }}
					</router-link>
				</li>
			</ul>
			<div
				class="footer__contact flex"
				:class="{'pt-0': $route.meta.minimalFooter}">
				<div class="flex flex-col">
					<h5 class="mb-8">MOLONGLO</h5>
					<div class="flex">
						<p
							class="footer__contact-loc2ations"
							v-html="content.locations"></p>
						<a class="footer__contact-phone" :href="'tel:' + content.number">
							<p v-html="content.number"></p>
						</a>
						<a class="footer__contact-email" :href="'mailto:' + content.email">
							<p v-html="content.email"></p>
						</a>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import Newsletter from '@/components/Newsletter.vue'
import rafSchd from 'raf-schd'

export default {
	name: 'AppFooter',
	components: {
		Newsletter
	},
	data() {
		return {
			content: null,
			includeCopyright: false,
			showNewsletter: false,
			resizeHandler: null
		}
	},
	watch: {
		$route: function (route) {
			this.includeCopyright = route.meta.includeCopyright
		}
	},
	computed: {
		showingNewsletter() {
			return this.$route.name === 'subscribe' || this.showNewsletter
		}
	},
	async mounted() {
		this.content = await fetch(
			`${import.meta.env.VITE_APP_API_ADDRESS}/footer`
		).then((res) => res.json())

		this.resizeHandler = rafSchd(() => {
			this.updateFooterHeight()
		})

		setTimeout(() => {
			this.updateFooterHeight()
		}, 500)
		window.addEventListener('resize', this.resizeHandler)
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.resizeHandler)
	},
	methods: {
		updateFooterHeight() {
			let height = this.$refs.footer.offsetHeight
			height += parseInt(
				window
					.getComputedStyle(this.$refs.footer)
					.getPropertyValue('margin-top')
			)

			document.documentElement.style.setProperty(
				'--footer-height',
				`${height}px`
			)
		}
	}
}
</script>

<style lang="scss" scoped>
.subscribe-button {
	@include button(#fff, #000, 'sml', 'outline');
}

.footer {
	@apply bg-base;
	width: 100%;
	padding: calc(#{$site-padding-lrg} * 2.5) $site-padding $site-padding-lrg
		$site-padding;
	align-self: flex-end;
	color: #fff;
	position: relative;

	.footer-container {
		width: 100%;
		margin: auto;
		color: #ffffff;
	}

	&--copyright {
		margin: 5.25rem 0 0 0;
	}

	&__copyright {
		@apply bg-grey-light;
		width: 100%;
		margin: auto;
		color: #000;
		padding: #{$site-padding * 3} $site-padding-lrg;
		position: absolute;
		top: -83px;
		left: 0;
	}

	&__links {
		display: flex;
		margin: 0 0 calc(#{$site-padding-lrg} * 2) 0;

		li {
			margin: 0 $site-padding;

			&:first-child {
				margin-left: 0;
			}

			a {
				color: #fff;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	&__contact {
		padding: $site-padding-lrg 0 0 0;

		a {
			color: #fff;
			display: inline-block;
			margin: 0 $site-padding;

			&:hover {
				text-decoration: underline;
			}
		}

		&-locations {
			margin: 0 $site-padding 0 0;
		}
	}

	@include mobile {
		padding: $site-padding-lrg $site-padding;

		&--copyright {
			margin-top: 68px;
		}

		&__copyright {
			padding: $site-padding-lrg $site-padding;
			top: -68px;
			min-height: 68px;
			display: flex;
			align-items: center;
		}

		.footer-container {
			padding: 0;
		}

		p {
			@include caption-text--m;
		}

		&__newsletter {
			width: 100%;
		}

		&__links {
			flex-wrap: wrap;
			margin: 0 $site-padding-lrg 0 0;

			li {
				width: 50%;
				margin: 0 0 $site-padding 0;

				a {
					@include caption-text--m;
				}
			}
		}

		&__contact {
			h5 {
				@include caption-text--m;
			}

			a {
				display: none;
			}
		}
	}
}
</style>
