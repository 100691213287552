export const HeaderMenuStore = {
	namespace: false,
	state: {
		showCartLink: false,
		headerHidden: false,
		headerVisible: false,
		headerTransparent: false,
		currentPage: {
			path: '',
			name: ''
		}
	},
	getters: {
		getCartStatus: (state) => state.showCartLink,
		getCurrentPage: (state) => state.currentPage,
		getHeaderTransparent: (state) => state.headerTransparent
	},
	mutations: {
		setCartStatus(state, status) {
			state.showCartLink = status
		},
		setCurrentPage(state, currentPage) {
			state.currentPage = currentPage
		},
		setHeaderVisible(state, visible) {
			state.headerVisible = visible
		},
		setHeaderTransparent(state, val) {
			state.headerTransparent = val
		},
		setHeaderHidden(state, hidden) {
			state.headerHidden = hidden
		}
	}
}
