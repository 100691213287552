import {createRouter, createWebHistory} from 'vue-router'
import {store} from '@/store'
import {useAnalytics} from '@/composables/useAnalytics'

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			name: 'home',
			component: () => import('@/views/home/Home.vue'),
			meta: {
				showInHeader: false,
				includeCopyright: false,
				apiPath: `${import.meta.env.VITE_APP_API_ADDRESS}/home`
			}
		},
		{
			path: '/homepage/:month',
			name: 'HomepageArchive',
			component: () => import('@/views/home/Home.vue'),
			meta: {
				showInHeader: false,
				includeCopyright: false,
				apiPath: `${import.meta.env.VITE_APP_API_ADDRESS}/home`
			}
		},
		{
			path: '/practice',
			component: () => import('@/views/practice/Practice.vue'),
			children: [
				{
					path: '',
					name: 'Practice',
					component: () => import('@/views/practice/PracticeBase.vue'),
					meta: {
						includeCopyright: true,
						apiPath: `${import.meta.env.VITE_APP_API_ADDRESS}/practice`
					}
				},
				{
					path: 'search/:terms',
					name: 'Practice Search',
					component: () => import('@/views/practice/PracticeBase.vue'),
					meta: {
						showInHeader: false,
						includeCopyright: true,
						apiPath: `${import.meta.env.VITE_APP_API_ADDRESS}/practice/search`
					}
				}
			],
			meta: {
				includeCopyright: true,
				showInHeader: true,
				name: 'Practice'
			}
		},
		{
			path: '/practice/:slug',
			name: 'Practice Single',
			component: () => import('@/views/practice/PracticeSingle.vue'),
			meta: {
				showInHeader: false,
				includeCopyright: true,
				apiPath: `${import.meta.env.VITE_APP_API_ADDRESS}/practice`
			}
		},
		{
			path: '/theory',
			component: () => import('@/views/theory/Theory.vue'),
			children: [
				{
					path: '',
					component: () => import('@/views/theory/TheoryBase.vue'),
					name: 'Theory Base',
					meta: {
						showInHeader: false,
						includeCopyright: true,
						apiPath: `${import.meta.env.VITE_APP_API_ADDRESS}/theory`
					}
				},
				{
					path: 'articles',
					name: 'Theory Articles',
					component: () => import('@/views/theory/articles/TheoryArticles.vue'),
					meta: {
						showInHeader: false,
						includeCopyright: true,
						apiPath: `${import.meta.env.VITE_APP_API_ADDRESS}/theory/articles`
					}
				},
				{
					path: 'articles/search/:terms',
					name: 'Search',
					component: () => import('@/views/theory/articles/TheoryArticles.vue'),
					meta: {
						showInHeader: false,
						includeCopyright: true,
						apiPath: `${
							import.meta.env.VITE_APP_API_ADDRESS
						}/theory/articles/search`
					}
				},
				{
					path: 'publications',
					name: 'Theory Publications',
					component: () =>
						import('@/views/theory/publications/TheoryPublications.vue'),
					meta: {
						showInHeader: false,
						includeCopyright: true,
						apiPath: `${
							import.meta.env.VITE_APP_API_ADDRESS
						}/theory/publications`
					}
				},
				{
					path: 'publications/:slug',
					name: 'Theory Publication',
					component: () =>
						import('@/views/theory/publications/PublicationSingle.vue'),
					meta: {
						showInHeader: false,
						includeCopyright: true,
						apiPath: `${
							import.meta.env.VITE_APP_API_ADDRESS
						}/theory/publications`
					}
				}
				/*
				{
					path: 'series',
					name: 'Theory Series',
					component: () => import('@/views/theory/series/TheorySeries.vue'),
					meta: {
						showInHeader: false,
						includeCopyright: true,
					},
				},
				{
					path: 'series/:slug',
					name: 'Series Single',
					component: () => import('@/views/theory/series/SeriesSingle.vue'),
					meta: {
						showInHeader: false,
						includeCopyright: true,
					},
				},
*/
			],
			meta: {
				showInHeader: true,
				name: 'Theory'
			}
		},
		{
			path: '/theory/articles/:article',
			name: 'Theory Article',
			component: () => import('@/views/theory/articles/Article.vue'),
			meta: {
				showInHeader: false,
				includeCopyright: true,
				apiPath: `${import.meta.env.VITE_APP_API_ADDRESS}/theory/articles`
			}
		},
		{
			path: '/theory/journals/:journal',
			name: 'Theory Journal',
			component: () => import('@/views/theory/Journal.vue'),
			meta: {
				showInHeader: false,
				includeCopyright: true,
				apiPath: `${import.meta.env.VITE_APP_API_ADDRESS}/theory/journals`
			}
		},
		{
			path: '/spaces',
			component: () => import('@/views/spaces/Spaces.vue'),
			children: [
				{
					path: '',
					name: 'Spaces Home',
					component: () => import('@/views/spaces/SpacesHome.vue'),
					meta: {
						includeCopyright: false,
						apiPath: `${import.meta.env.VITE_APP_API_ADDRESS}/spaces`,
						removeTrailingSlash: true
					}
				},
				{
					path: 'collections',
					name: 'Collections',
					component: () => import('@/views/spaces/collections/Collections.vue'),
					meta: {
						showInHeader: false,
						includeCopyright: false,
						apiPath: `${import.meta.env.VITE_APP_API_ADDRESS}/collection`
					}
				},
				{
					path: 'collections/:slug',
					name: 'Collection',
					component: () => import('@/views/spaces/collections/Collection.vue'),
					meta: {
						showInHeader: false,
						includeCopyright: false,
						apiPath: `${import.meta.env.VITE_APP_API_ADDRESS}/collection`
					}
				},
				{
					path: ':slug',
					name: 'Space',
					component: () => import('@/views/spaces/SpaceLayout.vue'),
					meta: {
						showInHeader: false,
						includeCopyright: false
					}
				}
			],
			meta: {
				name: 'Spaces',
				showInHeader: true,
				includeCopyright: false,
				removeTrailingSlash: true
			}
		},
		{
			path: '/careers',
			name: 'Careers',
			component: () => import('@/views/Careers.vue'),
			meta: {
				name: 'Careers',
				showInHeader: false,
				includeCopyright: false,
				apiPath: '/careers'
			}
		},
		{
			path: '/about',
			name: 'About',
			component: () => import('@/views/about/About.vue'),
			meta: {
				name: 'About',
				showInHeader: true,
				includeCopyright: false,
				apiPath: `${import.meta.env.VITE_APP_API_ADDRESS}/about`
			}
		},
		{
			path: '/about/:pathMatch(.*)',
			name: 'About page',
			component: () => import('@/views/about/About.vue'),
			meta: {
				showInHeader: false,
				includeCopyright: false,
				apiPath: `${import.meta.env.VITE_APP_API_ADDRESS}/about`
			}
		},
		{
			path: '/privacy',
			name: 'Privacy',
			component: () => import('@/views/page/Page.vue'),
			meta: {
				showInHeader: false,
				includeCopyright: false,
				apiPath: `${import.meta.env.VITE_APP_API_ADDRESS}/privacy`
			}
		},
		{
			path: '/privacy-policy',
			name: 'Privacy Policy',
			component: () => import('@/views/page/Page.vue'),
			meta: {
				showInHeader: false,
				includeCopyright: false,
				apiPath: `${import.meta.env.VITE_APP_API_ADDRESS}/privacy`
			}
		},
		{
			path: '/terms-conditions',
			name: 'Terms and Conditions',
			component: () => import('@/views/page/Page.vue'),
			meta: {
				showInHeader: false,
				includeCopyright: false,
				apiPath: `${import.meta.env.VITE_APP_API_ADDRESS}/terms`
			}
		},
		{
			path: '/subscribe',
			name: 'subscribe',
			component: () => import('@/views/Subscribe.vue'),
			meta: {
				showInHeader: false,
				includeCopyright: false
			}
		},
		{
			path: '/:pathMatch(.*)*',
			name: '404 Page',
			component: () => import('@/views/page/Page404.vue'),
			meta: {
				showInHeader: false,
				includeCopyright: false,
				apiPath: `${import.meta.env.VITE_APP_API_ADDRESS}/privacy`
			}
		}
	],
	scrollBehavior(to, from, savedPosition) {
		if (from.hash && !to.hash) {
			return {left: 0, top: 0}
		}
		if (to.hash && from.hash !== to.hash) {
			return new Promise((resolve) => {
				setTimeout(() => {
					resolve({el: to.hash, top: _getHashElementScrollMarginTop(to.hash)})
				}, 800)
			})
		}

		if (to.name === 'About page') {
			return
		}

		if (to.path !== from.path) {
			if (savedPosition) {
				return savedPosition
			} else {
				return {
					left: 0,
					top: 0
				}
			}
		}
	}
})

function _getHashElementScrollMarginTop(selector) {
	try {
		const elem = document.querySelector(selector)
		if (elem) {
			return parseFloat(getComputedStyle(elem).scrollMarginTop)
		}
	} catch {}
	return 0
}

router.beforeEach((to, from, next) => {
	document.body.classList.add('loading')

	if (from.path === '/theory') {
		store.commit('setHeaderHidden', false)
		store.commit('setHeaderVisible', false)
	}

	const hasTrailingSlash = to.path.slice(-1) === '/'

	if (hasTrailingSlash && to.meta.removeTrailingSlash) {
		const pathNoTrailingSlash = to.path.slice(0, -1)

		// go to same path without trailing slash
		next({path: pathNoTrailingSlash})
	} else {
		next()
	}
})

if (import.meta.env.PROD) {
	const base = new URL(window.location.origin)
	const {analytics} = useAnalytics()

	router.afterEach((to) => {
		document.body.classList.remove('loading')

		analytics.page({
			url: `${base.origin}${to.path}`,
			path: to.path
		})
	})
} else {
	router.afterEach(() => {
		document.body.classList.remove('loading')
	})
}

export default router
