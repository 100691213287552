export const SpacesModalStore = {
	namespace: false,
	state: {
		activeModal: '',
		isActive: false
	},
	getters: {
		getModalStatus: (state) => state.activeModal,
		getModalEnabled: (state) => state.isActive
	},
	mutations: {
		setModalStatus(state, status) {
			state.activeModal = status
		},
		setModalEnabled(state, status) {
			state.isActive = status
		}
	}
}
