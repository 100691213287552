<template>
	<div
		id="app"
		:class="[
			appBackground,
			{
				'header--transparent': headerTransparent,
				'header--hidden': headerHidden,
				'header--visible': headerVisible,
				'subscribe-page': $route.name === 'subscribe'
			}
		]">
		<AppHeader />
		<router-view v-slot="{Component}">
			<transition name="page">
				<component :is="Component" />
			</transition>
		</router-view>
		<AppFooter />
		<Cart :active="cartActive" />
	</div>
</template>

<script>
import AppHeader from '@/components/Header.vue'
import AppFooter from '@/components/Footer.vue'
import Cart from '@/components/Cart.vue'

import {mapGetters, mapMutations, mapState} from 'vuex'
import {defineComponent} from 'vue'

export default defineComponent({
	name: 'App',
	components: {
		AppHeader,
		AppFooter,
		Cart
	},
	data() {
		return {
			cartActive: false,
			appBackground: 'white'
		}
	},
	computed: {
		...mapGetters(['getCacheItem']),
		...mapState({
			headerHidden: (state) => state.HeaderMenuStore.headerHidden,
			headerTransparent: (state) => state.HeaderMenuStore.headerTransparent,
			headerVisible: (state) => state.HeaderMenuStore.headerVisible
		})
	},
	methods: {
		...mapMutations(['setCacheItem'])
	},
	created() {
		// Ip Info
		if (!this.getCacheItem('ipinfo')) {
			fetch('https://country-codes-api-production.molonglo.workers.dev/me')
				.then((res) => res.json())
				.then((res) => {
					this.setCacheItem({
						item: 'ipinfo',
						data: res
					})
				})
		}
	},
	mounted() {
		this.$mitt.on('toggleCart', (state) => (this.cartActive = state))
		this.$mitt.on('setAppBackground', (state) => (this.appBackground = state))

		const isSafariBrowser = () =>
			navigator.userAgent.indexOf('Safari') > -1 &&
			navigator.userAgent.indexOf('Chrome') <= -1

		if (isSafariBrowser()) {
			document.body.classList.add('safari')
		}
	}
})
</script>

<style lang="scss">
@font-face {
	font-family: 'Parma';
	font-style: normal;
	font-weight: normal;
	src:
		url('/fonts/Parma-Normal.woff2') format('woff2'),
		url('/fonts/Parma-Normal.woff') format('woff');
}

@font-face {
	font-family: 'Parma';
	font-style: italic;
	font-weight: normal;
	src:
		url('/fonts/Parma-Normal-Italic.woff2') format('woff2'),
		url('/fonts/Parma-Normal-Italic.woff') format('woff');
}

#app {
	display: flex;
	flex-wrap: wrap;
	min-height: calc(100vh + var(--footer-height, 500px));

	.safari & {
		height: -webkit-fill-available;
	}

	&.black {
		background-color: #000;

		.header {
			background-color: #000;
			color: #fff;
		}
	}
}

.subscribe-page {
	&#app {
		min-height: 100%;
	}

	.footer {
		min-height: calc(100vh - var(--header-h, 35px));
		margin-top: var(--header-h, 35px);
	}
}
</style>
