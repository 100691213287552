import {createStore} from 'vuex'

// import modules
import {PracticeFilterStore} from '@/store/modules/PracticeFilterStore'
import {ContextMenuStore} from '@/store/modules/ContextMenuStore'
import {SpacesFilterStore} from '@/store/modules/SpacesFilterStore'
import {TranslationStore} from '@/store/modules/TranslationStore'
import {SpacesModalStore} from '@/store/modules/SpacesModalStore'
import {TheoryFilterStore} from '@/store/modules/TheoryFilterStore'
import {HeaderMenuStore} from '@/store/modules/HeaderMenuStore'
import {CartStore} from '@/store/modules/CartStore'
import {CacheStore} from '@/store/modules/CacheStore'

export const store = createStore({
	modules: {
		PracticeFilterStore,
		ContextMenuStore,
		SpacesFilterStore,
		TranslationStore,
		SpacesModalStore,
		TheoryFilterStore,
		HeaderMenuStore,
		CartStore,
		CacheStore
	}
})
