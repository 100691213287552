<template>
	<div
		:class="[
			`context-header--${getFormat}`,
			$store.state.ContextMenuStore.isSeries
				? 'context-header--series'
				: 'context-header--not-series',
			{
				'context-header--scrolled': scrolled
			}
		]"
		:style="{
			backgroundColor: `${getColor}`
		}"
		class="context-header">
		<template v-if="$store.state.ContextMenuStore.isSeries">
			<ul class="context-header__list context-header__list--series">
				<li class="item item--back">
					<router-link :to="`/theory/series`">
						<p v-html="`← All Series`"></p>
					</router-link>
				</li>
				<li class="item item--title">
					<p
						v-html="
							`${$store.state.ContextMenuStore.title.toUpperCase()} ${
								$store.state.ContextMenuStore.subheader !== null
									? $store.state.ContextMenuStore.subheader
									: ''
							}`
						"></p>
				</li>
				<li class="item item--toggle">
					<button
						@click="showSeries = true"
						v-html="`More in this Series`"></button>
				</li>
			</ul>
			<SeriesSidebar
				:active="showSeries"
				@toggle="(state) => (showSeries = state)" />
		</template>
		<template v-else>
			<ul
				class="context-header__list context-header__list--theory flex flex-wrap justify-between md:justify-end">
				<li
					class="item item--title"
					:class="{'item--title-has-cart': getNumberOfCartItems > 0}">
					<p v-if="getTitle" v-html="`${getTitle}`" />
				</li>
				<li class="item item--back">
					<p
						v-if="getNumberOfCartItems > 0"
						class="header__main-cart mr-4 cursor-pointer hover:text-grey-400"
						@click="toggleCart()">
						Cart ({{ getNumberOfCartItems }})
					</p>
					<p v-else>&nbsp;</p>
					<router-link
						v-if="getBackLink"
						:to="getBackLink"
						class="context-header__back lg:mr-4"
						>back
					</router-link>
				</li>
			</ul>
		</template>
		<div
			v-if="getWatchContentLength"
			:style="`width: ${width}%;`"
			class="context-header__marker" />
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import SeriesSidebar from '@/views/theory/series/SeriesSidebar.vue'

export default {
	name: 'ContextHeader',
	components: {
		SeriesSidebar
	},
	data() {
		return {
			color: 'white',
			pageScroll: null,
			scrolled: false,
			width: 0,
			showSeries: false,
			fromRoute: null
		}
	},
	computed: {
		...mapGetters([
			'getTitle',
			'getSubheader',
			'getColor',
			'getBackLink',
			'getContentLength',
			'getFormat',
			'getIsSpaces',
			'getWatchContentLength',
			'getNumberOfCartItems'
		])
	},
	methods: {
		scrollListener() {
			const scrollContentHeight = this.getContentLength - window.innerHeight
			this.width = (window.scrollY / scrollContentHeight) * 100
		},
		toggleCart() {
			this.$mitt.emit('toggleCart', true)
		}
	},
	mounted() {
		// add event listener of scroll to calculate scroll to article length
		window.addEventListener('scroll', this.scrollListener, {
			capture: true,
			passive: true
		})
	},
	beforeUnmount() {
		window.removeEventListener('scroll', this.scrollListener)
	}
}
</script>

<style lang="scss" scoped>
.context-header {
	@apply border-t border-solid border-grey-light;
	align-items: center;
	display: flex;
	height: 53px;
	left: 0;
	padding: 10px 0 6px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;

	&.context-header--film.context-header--not-series {
		color: #fff;

		.context-header__back {
			&:before,
			&:after {
				background-color: #fff;
			}
		}

		.context-header__marker {
			display: none;
		}
	}

	&--resource {
		.context-header__marker {
			display: none;
		}
	}

	&__back {
		@include close-button;
		position: relative;
		margin: 0 $content-padding;
	}

	&__list {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding: 0 $site-padding;
		width: 100%;

		&--theory {
			.item--title {
				max-width: calc(100vw - #{$site-padding * 2} - 20rem) !important;
			}
		}

		.item {
			&--title {
				left: 50%;
				max-width: calc(100vw - #{$site-padding * 2} - 340px);
				position: absolute;
				text-align: center;
				transform: translateX(-50%);
				width: 100%;

				p {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}

			&--back {
				@apply absolute right-[10px] flex;
			}

			button {
				@include button-resets;
				@apply text-body-m md:text-body;
			}
		}
	}

	&__marker {
		background-color: #000;
		height: 2px;
		position: absolute;
		top: 51px;
		width: 0%;
	}

	@include mobile {
		&__title {
			left: unset;
			line-height: 36px;
			padding-left: $site-padding;
			padding-top: 6px;
			text-align: left;
			transform: unset;
		}

		&__list {
			&--theory {
				.item {
					&--title {
						display: block;
						left: unset;
						line-height: 36px;
						max-width: calc(100% - 4rem) !important;
						position: relative;
						text-align: left;
						transform: unset;

						&-has-cart {
							max-width: calc(100% - 9rem) !important;
						}
					}
				}
			}

			&--series {
				.item {
					&--title {
						display: none;
					}
				}
			}
		}
	}
}
</style>
