export const TheoryFilterStore = {
	namespace: '',
	state: {
		topics: null,
		contributors: null,
		formats: [
			{
				name: 'Article',
				slug: 'article',
				path: `/theory/articles?format=article`
			},
			{name: 'Film', slug: 'film', path: `/theory/articles?format=film`},
			{name: 'Q&A', slug: 'QA', path: `/theory/articles?format=QA`},
			{
				name: 'Visual Article',
				slug: 'visual',
				path: `/theory/articles?format=visual`
			}
		]
	},
	getters: {
		getTopics: (state) => state.topics,
		getContributors: (state) => state.contributors,
		getFormats: (state) => state.formats
	},
	mutations: {
		setTopics(state, topics) {
			state.topics = topics
		},
		setContributors(state, contributors) {
			state.contributors = contributors
		},
		setFormats(state, formats) {
			state.formats = formats
		}
	}
}
