<template>
	<header>
		<div
			class="header"
			:class="{
				'header--active': menuActive,
				'header--transparent': getHeaderTransparent
			}">
			<ul class="header__main">
				<li>
					<router-link :to="'/'">
						<h1 class="ucase">Molonglo</h1>
					</router-link>
				</li>
				<li>
					<router-link
						v-show="pageActive"
						class="ucase header__main-current"
						:to="getCurrentPage.path">
						<h5 v-html="getCurrentPage.name"></h5>
					</router-link>
				</li>
				<li class="header__main-navigation">
					<button
						v-if="getNumberOfCartItems > 0"
						class="header__main-cart"
						@click="toggleCart()">
						Cart ({{ getNumberOfCartItems }})
					</button>
					<!-- <p
							class="break"
							v-if="getNumberOfCartItems > 0 && $route.path !== '/'"> / </p> -->
					<button
						v-if="
							!($route.name === 'home' || $route.name === 'HomepageArchive') ||
							width < 769
						"
						class="header__main-menu menu"
						:class="{
							'header__main-menu--active': menuActive
						}"
						@click="menuActive = !menuActive">
						<span class="flex leading-[1.1]" v-if="menuActive">
							<span>Close&nbsp;</span><span class="close-button"></span>
						</span>
						<span v-else>Menu</span>
					</button>
				</li>
			</ul>
			<div v-show="!pageActive" class="header__sub">
				<ul>
					<li v-for="(menuItem, key) in menuItems" :key="`menuItem${key}`">
						<router-link :to="menuItem.path">
							{{ menuItem.meta.name }}
						</router-link>
					</li>
				</ul>
			</div>
			<ContextHeader
				v-if="
					$route.name === 'Theory Article' ||
					$route.name === 'Theory Journal' ||
					$route.name === 'Theory Publication'
				" />
		</div>
		<div v-if="getHeaderTransparent" class="header__white-border" />
	</header>
	<div
		v-show="menuActive"
		class="fixed z-20 h-full w-full md:hidden"
		@click.passive="menuActive = false"></div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import ContextHeader from '@/components/ContextHeader.vue'
import {useWindowSize} from '@vueuse/core'

export default {
	name: 'AppHeader',
	components: {
		ContextHeader
	},
	setup() {
		const {width} = useWindowSize()
		return {width}
	},
	mounted() {
		this.$mitt.on('showSubnav', (state) => {
			this.menuActive = state
		})
	},
	data() {
		return {
			mediaQuery: null,
			menuActive: false,
			pageActive: this.getCurrentPage !== null,
			content: null
		}
	},
	computed: {
		...mapGetters([
			'getNumberOfCartItems',
			'getCurrentPage',
			'getHeaderTransparent'
		]),
		menuItems: function () {
			return this.$router.options.routes.filter((route) => {
				if (route.meta.showInHeader) {
					// console.log(route)
					return true
				}
			})
		}
	},
	watch: {
		menuActive: function (newVal) {
			this.pageActive = !newVal
			if (this.getCurrentPage) {
				// console.log(this.getCurrentPage)
				// this.setCurrentPage(false)
			}
		}
	},
	methods: {
		...mapMutations(['setCurrentPage']),
		toggleCart() {
			this.$mitt.emit('toggleCart', true)
		}
	}
}
</script>

<style lang="scss" scoped>
.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: #fff;
	color: #000;
	margin: auto;
	transform: translateY(0) translateZ(0);
	transition: transform 200ms ease-in-out;
	backface-visibility: hidden;
	z-index: 50;

	&__white-border {
		@apply absolute left-[10px] top-[var(--header-h,_35px)] z-10 h-[1px] w-[calc(100%_-_20px)] bg-white;
	}

	&--transparent {
		background-color: transparent;
		color: #fff;

		.header__sub {
			@apply bg-transparent;
		}

		.header__main {
			border: none;
		}

		h1,
		h5,
		a,
		button {
			&:hover {
				color: #fff !important;
			}
		}

		@include mobile {
			&.header--active {
				.header__main,
				.header__sub {
					@apply backdrop-blur-sm;
				}

				.header__main {
					border-bottom: solid white $border-width;
				}

				+ .header__white-border {
					display: none;
				}
			}
		}
	}

	&--active {
		.header__sub {
			display: block;

			ul {
				opacity: 1;
			}
		}
	}

	.header--hidden & {
		transform: translateY(-100%);
		transition: transform 200ms ease-in-out;
	}

	.header--visible & {
		transform: translateY(0);
	}

	&__main {
		display: flex;
		background-color: inherit;
		padding: 8px 0 4px 0;
		margin: 0 $site-padding;
		border-bottom: $border-width solid;
		justify-content: space-between;

		h1 {
			@include body-text;

			&:hover {
				color: $dk-grey;
			}
		}

		h5 {
			&:hover {
				color: $dk-grey;
			}
		}

		a {
			color: inherit;
		}

		a {
			color: inherit;
		}

		button {
			@include button-resets;
			@include body-text;
			color: inherit;
			cursor: pointer;
		}

		&-current {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);

			@include mobile {
				display: none;
			}
		}

		&-menu {
			@apply flex items-end self-end;
			padding: 0 0 0 1.75rem !important;
			user-select: none;

			&:hover {
				color: $dk-grey;
				::before,
				::after {
					background-color: $dk-grey;
				}
			}

			&--active {
				padding: 0 0 !important;
				justify-content: space-between;
				position: relative;
			}
		}

		&-cart {
			&:hover {
				color: $dk-grey;
			}
		}

		&-navigation {
			display: flex;

			.break {
				padding: 0 0.25rem;
			}

			button {
				&:nth-child(2n) {
					margin-left: $site-padding;
				}
			}
		}
	}

	&__sub {
		display: none;
		position: absolute;
		left: 50%;
		top: 54%;
		transform: translateX(-50%) translateY(-50%);

		ul {
			opacity: 0;
			transition: opacity 200ms ease-in-out;
			display: flex;

			li {
				margin: 0 1rem;

				// text-transform:uppercase;
				a {
					@include body-text;
					color: inherit;

					&:hover {
						color: $dk-grey;
					}

					&.router-link-active {
						text-transform: uppercase;
					}
				}
			}
		}
	}

	@include mobile {
		width: 100%;
		border-bottom: unset;

		&__main {
			border-bottom: $border-width solid;
			margin: 0;
			padding: 8px $site-padding 4px;

			h1 {
				@include body-text;
			}
		}

		&__sub {
			transform: translateX(-50%);
			top: 100%;
			background-color: #fff;
			width: 100%;

			ul {
				padding: 16px $site-padding 6px $site-padding;
				flex-wrap: wrap;
				border-bottom: $border-width solid;

				li {
					width: 100%;
					margin: 0 0 $site-padding 0;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
</style>
