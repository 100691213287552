export const CacheStore = {
	namespace: false,
	state: {
		cache: []
	},
	getters: {
		getCacheItem: (state) => (key) => {
			return state.cache[key] ? JSON.parse(state.cache[key]) : false
		}
	},
	mutations: {
		setCacheItem(state, {item, data}) {
			state.cache = {
				...state.cache,
				[item]: JSON.stringify(data)
			}
		}
	}
}
