export const TranslationStore = {
	namespace: false,
	state: {
		active: false,
		language: 'English',
		translation: null
	},
	getters: {
		getActive: (state) => state.active,
		getLanguage: (state) => state.language,
		getTranslation: (state) => state.translation,
		getTranslationItem: (state) => (item) => {
			return state.translation[item]
		}
	},
	mutations: {
		setActive(state, active) {
			state.active = active
		},
		setLanguage(state, language) {
			state.language = language
		},
		setTranslation(state, translation) {
			state.translation = translation
		},
		setTranslationItem(state, {item, value}) {
			state.translation = {
				...state.translation,
				[item]: value
			}
		}
	}
}
