<template>
	<aside
		class="sidebar"
		:class="{
			'sidebar--active': active,
			'sidebar--articles':
				$store.state.ContextMenuStore.isSeries &&
				$store.state.ContextMenuStore.series.articles.length > 0
		}">
		<div class="sidebar__container">
			<header class="sidebar__top">
				<p>More in this series</p>
				<button @click="$emit('toggle', false)">Close</button>
			</header>
			<section class="sidebar__description">
				<p
					class="title"
					v-html="$store.state.ContextMenuStore.series.title"></p>
				<p
					class="contributors"
					v-html="
						$store.state.ContextMenuStore.series.contributors
							.map((contributor) =>
								contributor.type === 'individual'
									? `${contributor.individual.first_name} ${contributor.individual.last_name}`
									: contributor.studio.name
							)
							.join(', ')
					"></p>
				<div
					class="description"
					v-html="$store.state.ContextMenuStore.series.description"></div>
			</section>
			<ul class="sidebar__list">
				<li
					v-for="(article, key) in articles"
					:key="`article${key}`"
					class="article">
					<div v-if="article.featured_image" class="article__image">
						<router-link
							:to="`/theory/articles/${article.slug}`"
							@click.prevent="gotoArticle(article.slug)">
							<PictureComponent size="medium" :image="article.featured_image" />
						</router-link>
					</div>
					<div class="article__details">
						<router-link
							:to="`/theory/articles/${article.slug}`"
							@click.prevent="gotoArticle(article.slug)">
							<p class="name" v-html="article.name"></p>
							<p class="subheader" v-html="article.subheader"></p>
						</router-link>
					</div>
				</li>
			</ul>
		</div>
	</aside>
</template>

<script>
import PictureComponent from '@/components/PictureComponent.vue'

export default {
	name: 'SeriesSidebar',
	components: {PictureComponent},
	props: {
		active: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			articles: []
		}
	},
	watch: {
		'$store.state.ContextMenuStore.series.articles': function (items) {
			this.articles =
				items && items.length > 0
					? items.filter(
							(article) => article.slug !== this.$route.params.article
						)
					: []
		}
	},
	created() {
		const articles = this.$store.state.ContextMenuStore.series.articles
		this.articles =
			articles && articles.length > 0
				? articles.filter(
						(article) => article.slug !== this.$route.params.article
					)
				: []
	},
	methods: {
		async gotoArticle(path) {
			this.$emit('toggle', false)
			await this.$router.push(`/theory/articles/${path}`)
			setTimeout(
				() => document.querySelector('.sidebar__container').scrollTo(0, 0),
				400
			)
		}
	}
}
</script>

<style lang="scss" scoped>
$width: calc(25% + #{$site-padding-lrg});
$right: calc(-25% + #{-$site-padding * 2});
$mobile-width: calc(80% + #{$site-padding-lrg});
$mobile-right: calc(-80% + #{-$site-padding * 2});

.sidebar {
	position: fixed;
	top: 0;
	right: $right;
	width: $width;
	height: 100%;
	min-height: 100vh;
	display: flex;
	background-color: #000;
	color: #fff;
	z-index: 100;
	transition: right 400ms ease-in-out;

	&--active {
		right: 0 !important;
	}

	p {
		color: #fff;
	}

	&__container {
		width: 100%;
		overflow: scroll;
		position: relative;
	}

	&__top {
		display: flex;
		height: 53px;
		align-items: center;
		justify-content: center;
		width: calc(100% - (#{$site-padding-lrg} * 2));
		padding: $site-padding $site-padding-lrg;
		background-color: #000;
		width: 100%;

		p {
			color: #fff;
		}

		button {
			font-size: 0;
			height: 25px;
			width: 25px;
			position: absolute;
			right: $site-padding-lrg;
			border: none;
			background-color: transparent;
			cursor: pointer;
			transition: transform 200ms ease-in-out;

			&:hover {
				transform: rotate(90deg);
			}

			&:before,
			&:after {
				content: '';
				position: absolute;
				display: block;
				top: 50%;
				left: 50%;
				width: 2px;
				height: 100%;
				background-color: #fff;
			}

			&:before {
				transform: translate(-50%, -50%) rotate(-45deg);
			}

			&:after {
				transform: translate(-50%, -50%) rotate(45deg);
			}
		}
	}

	&__description {
		padding: #{$site-padding * 2} $site-padding-lrg 0 $site-padding-lrg;

		p {
			@include caption-text;
		}

		.title {
			text-transform: uppercase;
		}

		.description {
			margin: $site-padding-lrg 0 0 0;

			&:deep(p) {
				@apply text-caption;
				margin: 0 0 1.125rem 0;
				display: none;

				&:first-child {
					display: block;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		// padding: $site-padding 0 0 0;
		padding: #{$site-padding * 2} $site-padding-lrg $site-padding-lrg $site-padding-lrg;

		.article {
			width: 100%;
			margin: 0 0 $site-padding-lrg 0;

			&:nth-child(4n + 4) {
				margin-right: 0;
			}

			&__image {
				// height: calc((25vw - #{$site-padding * 4} - #{$site-padding * 3 / 4}) * 1.25);
				margin: 0 0 #{$site-padding} 0;

				:deep(img) {
					width: 100%;
					height: auto;
					// object-fit: contain;
					// object-position: 0% 0%;
				}
			}

			&__details {
				p {
					@apply text-caption;

					&.name {
						text-transform: uppercase;
					}
				}
			}
		}
	}

	@include mobile {
		right: $mobile-right;
		width: $mobile-width;

		&__top {
			justify-content: flex-start;
			border-bottom: $border-width solid #fff;
			padding-left: #{$site-padding * 1.5};
			padding-right: #{$site-padding * 1.5};
		}

		&__description {
			padding: #{$site-padding * 2} #{$site-padding * 1.5} 0 #{$site-padding *
				1.5};
		}

		&__list {
			padding: #{$site-padding * 4} #{$site-padding * 1.5} #{$site-padding * 6} #{$site-padding *
				1.5};

			.article {
				margin: 0 0 #{$site-padding * 4} 0;

				&:last-child {
					margin-bottom: 0;
				}

				&__image {
					display: flex;

					// height: calc((100vw - #{$site-padding * 2}) * 1);
					// max-height: calc((100vw - #{$site-padding * 2}) * 1);
					a {
						display: inline-block;

						:deep(img) {
							float: left;
						}
					}
				}

				&__details {
					p {
						@apply text-body-m;
					}
				}
			}
		}
	}
}
</style>
