<template>
	<div id="newsletter" class="newsletter">
		<form
			ref="form"
			v-show="!formSubmitted"
			class="newsletter-form"
			@change="formChange"
			:class="{loading}"
			@submit.prevent="submitNewsletterRequest">
			<input type="text" autocomplete="new-password" class="h" name="name" />
			<input
				v-model="fname"
				type="text"
				name="first_name"
				@input="checkValidity"
				placeholder="First name"
				autocomplete="off"
				class="sm:w-1/2"
				required
				:disabled="loading" />
			<input
				v-model="lname"
				type="text"
				name="last_name"
				@input="checkValidity"
				placeholder="Last name"
				autocomplete="off"
				class="flex-1"
				required
				:disabled="loading" />
			<input
				v-model="email"
				type="email"
				name="email"
				placeholder="Email address"
				autocomplete="off"
				@input="checkValidity"
				required
				:disabled="loading" />
			<CityFormField
				@selected="setCityOfResidence"
				:disabled="loading"
				@input="checkValidity"
				subscribe
				class="w-full text-white" />

			<div class="mt-6 w-full sm:mt-8">
				<p class="mb-1 uppercase">Interests</p>
				<label for="all-interest" class="mb-8"
					><input
						id="all-interest"
						type="checkbox"
						:disabled="loading"
						v-model="selectAllInterests"
						@change="selectAllChange" />
					<span>Select all</span>
				</label>

				<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-8">
					<ul class="flex flex-col gap-4 sm:gap-8">
						<li v-for="item in interests[0]" :key="item.slug">
							<label :for="item.slug">
								<input
									type="checkbox"
									class="validate-checkbox"
									name="interests[]"
									@change="checkboxChange"
									:id="item.slug"
									:value="item.slug"
									required
									v-model="selectedInterests"
									:disabled="loading" />
								<span class="flex flex-col">
									<span class="uppercase" v-text="item.title" />
									<span v-text="item.description" />
								</span>
							</label>
						</li>
					</ul>

					<ul class="flex flex-col gap-4 sm:gap-8">
						<li v-for="item in interests[1]" :key="item.slug">
							<label :for="item.slug">
								<input
									type="checkbox"
									name="interests[]"
									class="validate-checkbox"
									@change="checkboxChange"
									:id="item.slug"
									:value="item.slug"
									required
									v-model="selectedInterests"
									:disabled="loading" />
								<span class="flex flex-col">
									<span class="uppercase" v-text="item.title" />
									<span v-text="item.description" />
								</span>
							</label>
						</li>
					</ul>
				</div>
			</div>

			<label for="agree_to_terms" class="radio mb-4 flex w-full"
				><input
					type="checkbox"
					:disabled="loading"
					name="agree_to_terms"
					required
					@change="checkboxChange"
					v-model="agree_to_terms"
					id="agree_to_terms" /><span class="block"
					>I agree to the
					<a target="_blank" href="https://molonglo.com/privacy-policy"
						>privacy policy</a
					>
					and
					<a target="_blank" href="https://molonglo.com/terms-conditions"
						>terms and conditions</a
					></span
				></label
			>

			<button :disabled="!formValid || loading" class="newsletter-form__button">
				Submit
			</button>
		</form>
		<div class="newsletter__messages mt-[1em]">
			<div v-if="errors.length" class="newsletter__messages-errors">
				<p v-for="(error, key) in errors" :key="`error${key}`">
					<span v-text="error"></span>
				</p>
			</div>
			<div v-if="formSubmitted && success" class="newsletter__messages-success">
				<p v-html="success"></p>
			</div>
		</div>
	</div>
</template>

<script>
import {capitalCase, createFormNote, emailRegex, formDataToObj} from '@/utils'
import CityFormField from '@/components/CityFormField.vue'
import {ref} from 'vue'
import wretch from 'wretch'

export default {
	name: 'Newsletter',
	components: {CityFormField},
	data() {
		return {
			loading: false,
			email: null,
			fname: null,
			lname: null,
			selectedInterests: [],
			selectAllInterests: false,
			formSubmitted: false,
			agree_to_terms: false,
			country: '',
			errors: [],
			success: false,
			interests: [
				[
					{
						title: 'Molonglo Spaces',
						slug: 'Molonglo Spaces',
						description: 'Residential and commercial spaces to buy or lease.',
						showSpaces: true
					},
					{
						title: 'Dairy Road',
						slug: 'Dairy Road',
						description:
							'A neighbourhood slowly developing in Canberra’s East Lake.'
					},
					{
						title: 'Public Programs',
						slug: 'Public Programs',
						description:
							'Workshops, exhibitions, dinners, talks and other events related to our projects.'
					}
				],
				[
					{
						title: 'Public Office',
						slug: 'Melbourne',
						description: 'A new centre for creative work in Collingwood.'
					},
					{
						title: 'Greece',
						slug: 'Greece',
						description:
							'Refurbished apartments for rent in Koukaki and Kypseli, Athens.'
					},
					{
						title: 'Publishing',
						slug: 'Publishing',
						description:
							'Books that support a better understanding of the built environment.'
					}
				]
			]
		}
	},
	setup() {
		const formValid = ref()
		const form = ref()
		const cityOfResidence = ref(false)

		const checkValidity = () => {
			formValid.value = form.value.checkValidity() && cityOfResidence.value
		}

		const formData = ref({})
		function formChange() {
			// Save current page form data
			const data = formDataToObj(new FormData(form.value))
			formData.value = {...formData.value, ...data}
		}

		return {
			formData,
			formChange,
			formValid,
			form,
			checkValidity,
			cityOfResidence
		}
	},
	computed: {
		tags() {
			const t = [
				'Molonglo - Newsletter Subscriber',
				...this.selectedInterests.filter((s) => s !== 'Dairy Road')
			]

			if (this.selectedInterests.includes('Dairy Road')) {
				t.push('Dairy Road - Current')
				t.push('Dairy Road - Future')
			}

			return t
		}
	},
	methods: {
		setCityOfResidence(city) {
			this.cityOfResidence = city
			this.checkValidity()
		},
		checkboxChange() {
			const checkboxes = this.$refs.form.querySelectorAll(
				'input.validate-checkbox'
			)
			const checked = Array.from(checkboxes).filter((item) => item.checked)
			if (checked.length > 0) {
				checkboxes.forEach((checkbox) => {
					checkbox.removeAttribute('required')
				})
			} else {
				checkboxes.forEach((checkbox) => {
					checkbox.setAttribute('required', 'true')
				})
			}
			this.checkValidity()
		},
		selectAllChange(e) {
			this.interests.forEach((g) => {
				g.forEach((i) => {
					if (e.target.checked) {
						this.selectedInterests.push(i.slug)
					} else {
						this.selectedInterests = this.selectedInterests.filter(
							(s) => s !== i.slug
						)
					}
				})
			})
			this.$nextTick(() => {
				this.checkboxChange()
			})
		},
		submitNewsletterRequest() {
			if (this.loading || !this.cityOfResidence) return
			this.loading = true
			this.errors = []

			/* honeypot */
			if (this.formData['name']) {
				this.loading = false
				this.resetForm(0)
				return
			}

			const body = {
				tags: this.tags,
				email: this.email,
				city_of_residence: this.cityOfResidence,
				country: this.country,
				first_name: capitalCase(this.fname),
				last_name: capitalCase(this.lname),
				agree_to_terms: this.agree_to_terms,
				create_person: true
			}

			wretch(`${import.meta.env.VITE_APP_API_ADDRESS}/subscribe`)
				.post({
					...body,
					note: createFormNote(body)
				})
				.json((res) => {
					if (res.success) {
						this.formSubmitted = true
						this.success = 'Thank you for subscribing.'
						this.resetForm(10 * 1000)
					} else if (res.error || res.code === 'error') {
						this.errors.push(res.message)
					}
				})
				.catch(() => {
					this.errors.push('An error occurred.')
				})
				.finally(() => {
					this.loading = false
				})
		},
		validateForm() {
			this.formSubmitted = true
			// email validation
			if (
				this.email &&
				typeof this.email !== typeof null &&
				this.email.length
			) {
				// check for validate email
				if (!emailRegex.test(this.email)) {
					this.errors.push(
						'This email cannot be added. Please enter a different email address.'
					)
					this.resetForm(4000)
				}
			} else {
				this.errors.push('This email field is required')
				this.resetForm(4000)
			}
			// return false & remove errors after 4 seconds
			if (this.errors && this.errors.length) {
				return false
			}
			// form passed validation
			return true
		},
		resetForm(time) {
			setTimeout(() => {
				this.errors = []
				this.success = false
				this.formSubmitted = false
				this.email = null
				this.fname = null
				this.lname = null
				this.selectedInterests = []
				this.selectAllInterests = false
			}, time)
		}
	}
}
</script>

<style lang="scss" scoped>
.newsletter {
	margin: calc(#{$site-padding-lrg} * 2) 0 $site-padding-lrg 0;

	a {
		&:hover {
			@apply text-grey;
		}
	}

	&-form {
		@apply flex flex-wrap gap-x-8 gap-y-4 text-white;
		margin-top: 0.25rem;

		&.loading {
			&,
			* {
				cursor: wait !important;
			}
		}

		label {
			@apply flex cursor-pointer select-none;
		}

		label,
		input {
			@apply leading-[1.1];
		}

		input {
			@apply flex-shrink-0 border-0 border-b border-solid border-white px-2 py-2;

			&:disabled {
				&,
				&::placeholder {
					@apply text-grey;
				}
			}

			&.error {
				@apply border-[red];
			}

			&[type='checkbox'] {
				@apply border border-white;
				@apply relative;
				appearance: none;
				width: 20px;
				height: 20px;
				margin-right: $site-padding;

				&:disabled + span {
					@apply text-grey;
				}

				&:checked {
					@apply bg-white;
					/*
					&:before {
						@apply content-[''] absolute bg-white rounded-full w-[10px] h-[10px] top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2;
					}
					*/
				}

				@include mobile {
					width: 16px;
					height: 16px;
				}
			}

			&::placeholder {
				@apply text-white;
			}
		}

		&__button {
			@include button(#fff, #000, 'sml', 'outline');

			&:disabled {
				@apply border-grey text-grey hover:border-grey hover:bg-black hover:text-grey;
			}
		}
	}

	&__messages {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		&-errors {
			color: red;
			p {
				padding: 0.5rem 0 0 0;
			}
		}

		&-success {
			p {
				color: #fff;
			}
		}
	}

	@include mobile {
		margin: $site-padding 0 $site-padding-lrg 0;

		&-form {
			:deep(label),
			:deep(label *),
			:deep(input) {
				@apply text-caption-m;
			}

			&__button {
				@include button(#fff, #000, 'sml', 'outline');
			}
		}

		&__messages {
			&-errors,
			&-success {
				p {
					@apply text-caption-m;
				}
			}
		}
	}
}
</style>
