export const PracticeFilterStore = {
	namespace: false,
	state: {
		types: null,
		locations: null
	},
	getters: {
		// myModularizedNumber: state => state.myModularizedNumber,
		getTypes: (state) => state.types,
		getLocations: (state) => state.locations
	},
	mutations: {
		setTypes(state, types) {
			state.types = types
		},
		setLocations(state, locations) {
			state.locations = locations
		}
	}
}
