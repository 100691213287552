import App from './App.vue'
import router from './router'
import {store} from './store'
import './styles/modules.scss'
import {createApp} from 'vue'
import {createHead} from '@unhead/vue'
import mitt from 'mitt'
import * as Sentry from '@sentry/vue'

// todo: Make all routes PHP have permission_callback to enforce auth
const headers = {
	Authorization: `Basic YXBwOk1qT0cgQjdReCB5bVVTIFRSZ0ogSE1NSSAxNWEy`
}

const emitter = mitt()
const head = createHead()
const app = createApp(App)

app.config.globalProperties.$headers = headers
app.config.globalProperties.$mitt = emitter

if (!import.meta.env.DEV) {
	Sentry.init({
		app,
		dsn: 'https://3075537410fc4464e226bd35d0d33227@o4506663785463808.ingest.sentry.io/4506663791558656',
		integrations: [Sentry.replayIntegration()],
		replaysOnErrorSampleRate: 1.0,
		ignoreErrors: ['Object.hasOwn', 'Failed to fetch'],
		denyUrls: [/googleapis\.com/i],
		environment: import.meta.env.MODE
	})
}

app.use(store)
app.use(router)
app.use(head)
app.mount('#vue')

app.config.globalProperties.$http = async (url, method = 'GET', data) => {
	try {
		const response = await fetch(url, {
			method,
			data
		})

		return await response.json()
	} catch (error) {
		if (error.message === 'Unexpected end of JSON input') {
			return false
		} else {
			throw error
		}
	}
}

app.config.globalProperties.$fetchAndCache = async (path) => {
	const data = store.getters.getCacheItem(path)
	if (data) {
		return data
	}

	const url = `${import.meta.env.VITE_APP_API_ADDRESS}/${path}`

	return fetch(url, {headers})
		.then((response) => response.json())
		.then((response) => {
			store.commit('setCacheItem', {
				item: path,
				data: response
			})
			return response
		})
}
