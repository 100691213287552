export const ContextMenuStore = {
	namespace: false,
	state: {
		title: null,
		slug: null,
		subheader: null,
		color: '#fff',
		backLink: '/theory/articles',
		contentLength: 0,
		format: null,
		// series properties
		isSeries: false,
		series: {
			title: '',
			subheader: '',
			articles: []
		},
		isSpaces: false,
		nextIndex: false,
		watchContentLength: false
	},
	getters: {
		getTitle: (state) => state.title,
		getSubheader: (state) => state.subheader,
		getColor: (state) => state.color,
		getBackLink: (state) => state.backLink,
		getContentLength: (state) => state.contentLength,
		getWatchContentLength: (state) => state.watchContentLength,
		getFormat: (state) => state.format,
		getIsSpaces: (state) => state.isSpaces
	},
	mutations: {
		setTitle(state, title) {
			state.title = title
		},
		setSlug(state, slug) {
			state.slug = slug
		},
		setSubheader(state, subheader) {
			state.subheader = subheader
		},
		setColor(state, color) {
			state.color = color
		},
		setBackLink(state, backLink) {
			state.backLink = backLink
		},
		setContentLength(state, contentLength) {
			state.contentLength = contentLength
		},
		setWatchContentLength(state, watchContentLength) {
			state.watchContentLength = watchContentLength
		},
		setFormat(state, format) {
			state.format = format
		},
		setIsSeries(state, isSeries) {
			state.isSeries = isSeries
		},
		setSeries(state, series) {
			state.series = series
		},
		setNextIndex(state, index) {
			state.nextIndex = index
		},
		setIsSpaces(state, value) {
			state.isSpaces = value
		}
	},
	actions: {
		SET_CONTEXT_MENU({commit}, payload) {
			if (payload.title !== undefined) {
				commit('setTitle', payload.title)
			}

			if (payload.slug !== undefined) {
				commit('setSlug', payload.slug)
			}

			if (payload.subheader !== undefined) {
				commit('setSubheader', payload.subheader)
			}

			if (payload.color !== undefined) {
				commit('setColor', payload.color)
			}

			if (payload.format !== undefined) {
				commit('setFormat', payload.format)
			}

			if (payload.contentLength !== undefined) {
				commit('setContentLength', payload.contentLength)
			}

			if (payload.watchContentLength !== undefined) {
				commit('setWatchContentLength', payload.watchContentLength)
			}

			if (payload.backLink !== undefined) {
				commit('setBackLink', payload.backLink)
			}

			if (payload.isSpaces !== undefined) {
				commit('setIsSpaces', payload.isSpaces)
			}

			return new Promise((resolve) => resolve(true))
		},
		SET_SERIES({commit, state}, payload) {
			commit('setIsSeries', payload.show)

			commit('setSeries', payload.series)

			const current = payload.currentIndex

			const nextIndex =
				state.series && current < state.series.articles.length - 1
					? current + 1
					: 0

			commit('setNextIndex', nextIndex)

			return new Promise((resolve) => resolve(true))
		}
	}
}
